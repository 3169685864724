<template>
    <div class="profile-content shadow mt-3">
        <h3 class="card-title mb-3">Change Username</h3>
        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
            class="project-form"
            label-position="top"
        >
            <div class="row">
                <div class="col-md-6 col-lg-4">
                    <el-form-item
                        label="Enter current username (email)"
                        prop="current_email"
                    >
                        <el-input
                            :value="authUser.email"
                            type="email"
                            @keyup.enter.native="handleSubmit('form')"
                            placeholder="old@gmail.com"
                            disabled
                        />
                    </el-form-item>
                    <el-form-item
                        label="Enter new username (email)"
                        prop="new_email"
                        :error="formData.new_email_error"
                    >
                        <el-input
                            v-model="formData.new_email"
                            @keyup.enter.native="handleSubmit('form', code_field_hidden)"
                            type="email"
                            placeholder="e.g., new@gmail.com"
                        />
                    </el-form-item>
                      <el-form-item
                          label="Enter verification code"
                          prop="code"
                          v-if="!code_field_hidden"
                          class="w-100 px-1"
                          :error="formData.code_error"
                      >
                      <el-input
                            v-model="formData.code"
                            @keyup.enter.native="handleSubmit('form', code_field_hidden)"
                            type="text"
                            placeholder="e.g., 123456"
                        />
                      </el-form-item>
                      <div class="d-flex justify-content-end">
                      <el-form-item>
                        <el-button
                            style="margin-top: 30px"
                            type="success"
                            :loading="loading"
                            @click="handleSubmit('form', code_field_hidden)">
                          {{ changingEmail ? 'Send Code' : 'Update Email' }}
                        </el-button>
                      </el-form-item>
                      <el-form-item
                          v-if="!code_field_hidden"
                      >
                        <el-button
                            style="margin-top: 30px"
                            class="mx-1"
                            type="success"
                            :loading="loading"
                            @click="handleSubmit('form', true)">
                          Resend
                        </el-button>
                      </el-form-item>
                      </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";
import {mapGetters} from "vuex";

export default {
    name: "ChangeUsername",
    data() {
        return {
            formData: {
                current_email: null,
                new_email: null,
                code: null,
                code_error:null,
                current_email_error: null,
                new_email_error: null,
            },
            errors: [],
            rules: {
                new_email: [
                    {required: true, message: "Please enter your new email", trigger: "blur"},
                    {type: "email", message: "Please enter a valid email", trigger: "blur"}
                ]
            },
            loading: false,
          code_field_hidden : true,
        }
    },
    computed: {
      ...mapGetters('auth', ["authUser"]),
      changingEmail(){
        return this.formData.new_email && this.code_field_hidden;
      }
    },
    methods: {
        handleSubmit(formName, sendCode) {
          if(sendCode && this.formData.new_email){
            axios.post('auth/send-passcode', {
              email: this.formData.new_email,
            })
                .then((response) => {
                  Notification.success('Passcode has been sent to your new email');
                  console.log(response.data);
                  this.code_field_hidden = false;
                })
                .catch((error) => {
                  Notification.error(error.response.data.message);
                  this.errors = error.response.data.errors;
                  console.log(error)
                })
                .finally(() => {
                  this.loading = false;
                });
          }else{
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.callToBackendChangeUserNameApi();
                } else {
                    return false;
                }
            });
          }
        },
        callToBackendChangeUserNameApi() {
            this.formData.current_email_error = null;
            this.formData.new_email_error = null;
            axios.post("/account/change-email", {
                current_email: this.authUser.email,
                new_email: this.formData.new_email,
                code: this.formData.code
            })
                .then(response => {
                    this.formData.current_email = null;
                    this.formData.new_email = null;
                    this.$router.push({name: 'MainDashboard'});
                    this.$store.dispatch('auth/getUserProfile');
                    Notification.success('Your username has been changed successfully!');
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        // Notification.error({
                        //     title: "Error",
                        //     message: error.response.data.message,
                        //     type: "error"
                        // });
                    } else {
                        Notification.error({
                            title: "Error",
                            message: error.response.data.message,
                            type: "error"
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

    },
    watch: {
        'errors': {
            handler(value) {
                if (value.current_email) {
                    this.formData.current_email_error = value.current_email.toString();
                }
                if (value.new_email) {
                    this.formData.new_email_error = value.new_email.toString();
                }
              if (value.code) {
                this.formData.code_error = value.code.toString();
              }
            },
            deep: true,
        },
        'formData.new_email':{
          handler(value) {
            this.code_field_hidden = true;
          },
          deep: true,
        }
    }
}
</script>

<style scoped>

</style>