<template>
    <div class="profile-content shadow mt-3">
        <h3 class="card-title mb-3">Profile
            Details</h3>
        <el-form ref="form" :model="formData"
            :rules="rules"
            class="project-form"
            label-position="top">
            <div class="row">
                <div class="col-lg-4">
                    <!-- <img
                                v-if="imageShow !== null"
                                :src="`${baseUrl}`+imageShow"
                                class="rounded-circle me-3 img-fluid"
                                style="width: 120px; height: 120px"
                                :alt="authUser.first_name + ' ' + authUser.last_name"
                            /> -->
                    <el-upload
                        class="avatar-uploader d-inline-block mb-2"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-change="handleChange"
                        :on-success="handleUploadSuccess"
                        :on-remove="handleRemove"
                        :auto-upload="false">
                        <img v-if="imageShow"
                            :src="imageShow"
                            class="avatar">
                        <img v-else-if="preview != null"
                            :src="preview"
                            class="avatar" alt="">
                        <i v-else
                            class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <p class="small mb-2"
                        v-if="!imageShow">jpg/png
                        files with a size less
                        than 512KB</p>
                    <div slot="reference"
                        v-if="imageShow"
                        class="text-danger cursor-pointer mb-3"
                        @click="getRemoveProfileModal">
                        Remove Profile Pic
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-md-6">
                            <el-form-item
                                label="First Name"
                                prop="first_name">
                                <el-input
                                    v-model="formData.first_name"
                                    type="text"
                                    placeholder="Enter your first name" />
                            </el-form-item>
                        </div>
                        <div class="col-md-6">
                            <el-form-item
                                label="Last Name"
                                prop="last_name">
                                <el-input
                                    v-model="formData.last_name"
                                    type="text"
                                    placeholder="Enter your last name" />
                            </el-form-item>
                        </div>
                        <div class="col-md-12">
                            <el-form-item
                                label="Full Street Name"
                                prop="address">
                                <el-input
                                    v-model="formData.address"
                                    type="text"
                                    placeholder="Enter your street’s name" />
                            </el-form-item>
                        </div>
                        <div class="col-md-6">
                            <el-form-item
                                label="City & State"
                                prop="city">
                                <el-input
                                    v-model="formData.city"
                                    type="text"
                                    placeholder="Enter your city & state" />
                            </el-form-item>
                        </div>
                        <div class="col-md-6">
                            <el-form-item label="Country" prop="country">
                                <model-list-select :list="Countries"
                                    v-model="formData.country"
                                    option-value="name"
                                    option-text="name"
                                    placeholder="Please Select Country">
                                </model-list-select>
                            </el-form-item>
                        </div>
                        <div class="col-md-6">
                            <el-form-item
                                label="Organization"
                                prop="organization">
                                <el-input
                                    v-model="formData.organization"
                                    type="text"
                                    placeholder="Enter your organization" />
                            </el-form-item>
                        </div>
                        <div class="col-md-6">
                            <el-form-item label="Profession" prop="profession">
                                <el-input
                                    v-model="formData.profession"
                                    type="text"
                                    placeholder="Enter your Profession" />
                                <!-- <model-list-select :list="Professions"
                                    v-model="formData.profession"
                                    option-value="name"
                                    option-text="name"
                                    placeholder="Please Select Profession">
                                </model-list-select> -->
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex justify-content-end">
                <el-button class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('form')">Update
                    Profile
                </el-button>
            </div>
        </el-form>
        <remove-profile-modal
            :show="showRemoveProfileModal"
            :text="`Do you want to remove your profile picture?`"
            @close="showRemoveProfileModal = false"
            @success="removeImage" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { Notification } from "element-ui";
import { EventBus } from "../../event-bus";
import RemoveProfileModal from "./RemoveProfileModal";
import CountriesJson from '../../store/countries.json';
import ProfessionsJson from '../../store/professions.json';
import { ModelListSelect } from 'vue-search-select'
export default {
    name: "ProfileDetails",
    components: { RemoveProfileModal, ModelListSelect },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                country: '',
                organization: '',
                profession: '',
                profile_picture: null,
            },
            uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/avatar`,
            baseUrl: `${process.env.VUE_APP_IMAGE_BASE_URL}`,
            imageShow: null,
            preview: null,
            rules: {
                first_name: [
                    { required: true, message: 'First Name is required', trigger: 'blur' },
                    { min: 3, max: 20, message: 'First Name must be between 3 and 20 characters', trigger: 'blur' },
                ],
                last_name: [
                    { required: true, message: 'Last Name is required' },
                    { min: 3, max: 20, message: 'Last Name must be between 3 and 20 characters', trigger: 'blur' },
                ],
                address: [
                    { required: false, message: 'Address is not required' },
                    { min: 3, max: 100, message: 'Address must be between 3 and 100 characters', trigger: 'blur' },
                ],
                city: [
                    { required: false, message: 'City is not required' },
                    { min: 3, max: 100, message: 'City must be between 3 and 100 characters', trigger: 'blur' },
                ],
                country: [
                    { required: false, message: 'Country is not required' },
                    { min: 3, max: 100, message: 'Country must be between 3 and 100 characters', trigger: 'blur' },
                ],
                organization: [
                    { required: false, message: 'Organization is not required' },
                    { min: 3, max: 100, message: 'Organization must be between 3 and 100 characters', trigger: 'blur' },
                ],
                profession: [
                    { required: false, message: 'Profession is not required' },
                    { min: 3, max: 100, message: 'Profession must be between 3 and 100 characters', trigger: 'blur' },
                ],
            },
            loading: false,
            showRemoveProfileModal: false,
            Countries: CountriesJson,
            Professions: ProfessionsJson,
        }
    },
    mounted() {
        this.getAuthUser();
    },
    computed: {
        ...mapGetters("auth", ["token", "authUser"]),
    },
    methods: {
        getRemoveProfileModal() {
            this.showRemoveProfileModal = true;
        },
        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.callToBackendUpdateProfileApi();
                } else {
                    return false;
                }
            });
        },

        callToBackendUpdateProfileApi() {
            let storageData = localStorage.vuex;
            let authData = JSON.parse(storageData);
            let token = authData.auth.authToken;


            let config = {
                headers: {
                    'Accept': 'application/json',
                    "Authorization": `Bearer ${token}`,
                    "content-type": "multipart/form-data"
                }
            }

            this.loading = true;
            let formData = new FormData();

            formData.append('first_name', this.formData.first_name)
            formData.append('last_name', this.formData.last_name)
            formData.append('address', this.formData.address)
            formData.append('city', this.formData.city)
            formData.append('country', this.formData.country)
            formData.append('organization', this.formData.organization)
            formData.append('profession', this.formData.profession)
            formData.append('profile_picture', this.formData.profile_picture)

            axios.post("/account/profile", formData, config)
                .then(() => {
                    this.formData.profile_picture = null;
                    this.$store.dispatch('auth/getUserProfile');
                    EventBus.$emit("profile-avatar-updated", true);
                    this.$router.push({ name: 'Profile' })
                    this.getAuthUser();
                    Notification.success('Your profile is updated successfully!');
                })
                .catch(() => {
                    Notification.error('Invalid Information!');
                })
                .finally(() => {
                    this.loading = false;
                });
        },


        handleChange(file) {
            const isJpgOrPng = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
            const isLt2M = file.raw.size / 1024 / 1024 < .5; //512 KB
            if (!isJpgOrPng) {
                Notification.error('You can only upload JPG/PNG file!');
                return isJpgOrPng && isLt2M;
            }
            if (!isLt2M) {
                Notification.error('Image must smaller than 512KB!');
                return isJpgOrPng && isLt2M;
            }

            let imageFile = file.raw;
            this.formData.profile_picture = imageFile
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result
            };
            reader.readAsDataURL(imageFile);
        },

        handleUploadSuccess(response) {
            EventBus.$emit("profile-avatar-updated", true);
            Notification.success(response.message);
            this.getAuthUser();
        },

        handleRemove(file) {
            this.preview = null;
        },

        getAuthUser() {
            axios.get('/account/profile').then((response) => {

                this.formData.first_name = response.data.data.first_name
                this.formData.last_name = response.data.data.last_name

                if (response.data.data.address != null) {
                    this.formData.address = response.data.data.address
                }
                if (response.data.data.city != null) {
                    this.formData.city = response.data.data.city
                }
                if (response.data.data.country != null) {
                    this.formData.country = response.data.data.country
                }
                if (response.data.data.organization != null) {
                    this.formData.organization = response.data.data.organization
                }
                if (response.data.data.profession != null) {
                    this.formData.profession = response.data.data.profession
                }
                this.imageShow = response.data.data.profile_picture_link


            }).catch((error) => {
                console.log(error)
            })
        },

        removeImage() {
            axios.delete('/account/avatar')
                .then((response) => {
                    EventBus.$emit("profile-avatar-updated", true);
                    this.getAuthUser();
                    this.showRemoveProfileModal = false;
                    this.preview = null;
                    this.imageShow = null;
                    Notification.success("Profile picture removed.");
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },
}
</script>

<style scoped>
.avatar-uploader {
    border: 1px dashed #00b050;
    width: 150px;
    height: 150px;
}

.avatar-uploader .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
}

.avatar {
    width: 148px;
    height: 148px;
    display: block;
}
</style>