<template>
    <div class="container-fluid p-0">
        <div class="page-headermb">
            <h2 class="main-content-title">Profile</h2>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="profile-related-tabs">
                    <sub-menu @change-menu="activeMenu = $event" />
                </div>
                <div class="profile-tab-content">
                    <div
                        v-if="activeMenu === '1'">
                        <profile-details />
                    </div>
                    <div
                        v-if="activeMenu === '2'">
                        <change-password />
                    </div>
                    <div
                        v-if="activeMenu === '3'">
                        <change-username />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubMenu from "../../components/profile/SubMenu";
import ProfileDetails from "../../components/profile/ProfileDetails";
import ChangePassword from "../../components/profile/ChangePassword";
import ChangeUsername from "../../components/profile/ChangeUsername";
import Icons from "../../components/common/Icons";

export default {
    name: "Index",
    components: { Icons, SubMenu, ChangeUsername, ChangePassword, ProfileDetails },
    data() {
        return {
            activeMenu: "1"
        };
    },


}
</script>

<style scoped>

</style>