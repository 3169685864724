<template>
    <div class="profile-content shadow mt-3">
        <h3 class="card-title mb-3">Change Password</h3>
        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
            class="project-form"
            label-position="top"
        >
            <div class="row">
                <div class="col-md-6 col-lg-4">
                    <el-form-item
                        label="Enter current password"
                        prop="old_password"
                    >
                        <el-input
                            v-model="formData.old_password"
                            @keyup.enter.native="handleSubmit('form')"
                            :type="showCurrentPassword ? 'text' : 'password'"
                            placeholder="********"
                        >
                            <el-button slot="append" @click.prevent="showCurrentPassword = !showCurrentPassword">
                                <svg v-if="showCurrentPassword" style="width: 15px; height: 15px" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                                <svg v-if="!showCurrentPassword" style="width: 15px; height: 15px" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                </svg>
                            </el-button>
                        </el-input>
                    </el-form-item>

                    <el-form-item
                        label="Enter new password"
                        prop="password"
                        :error="formData.passwordError"
                    >
                        <el-input
                            v-model="formData.password"
                            :type="showPassword ? 'text' : 'password'"
                            @keyup.enter.native="handleSubmit('form')"
                            placeholder="********"
                        >
                            <el-button slot="append" @click.prevent="showPassword = !showPassword">
                                <svg v-if="showPassword" style="width: 15px; height: 15px" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                                <svg v-if="!showPassword" style="width: 15px; height: 15px" fill="none"
                                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                </svg>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <div class="d-flex justify-content-end">
                        <el-button
                            class="mt-2"
                            type="success"
                            :loading="loading"
                            @click="handleSubmit('form')"
                        >Update Password
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios';
import {Notification} from "element-ui";

export default {
    name: "ChangePassword",
    data() {
        return {
            formData: {
                old_password: null,
                password: null,
                passwordError: null,
            },
            rules: {
                old_password: [
                    {required: true, message: 'Current Password is required', trigger: 'blur'},
                    {min: 8, message: 'Password must be at least 8 characters', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: 'New Password is required', trigger: 'blur'},
                    // {min: 8, message: 'Password must be at least 8 characters', trigger: 'blur'},
                    {
                        trigger: 'blur',
                        validator (rule, value, callback) {
                          if (/^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,}$/.test(value)) {
                            callback();
                          } else {
                            callback(new Error('Minimum 8 characters, One capital latter, Special charater, Alphanumeric'));
                          }
                        }
                    }
                ],
            },
            loading: false,
            showCurrentPassword: false,
            showPassword: false
        }
    },
    methods: {
        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.callToBackendChangePasswordApi();
                } else {
                    return false;
                }
            });
        },

        callToBackendChangePasswordApi() {
            this.formData.passwordError = null;
            axios.post('/account/change-password', this.formData)
                .then(response => {
                    this.formData.old_password = null;
                    this.formData.password = null;
                    this.$router.push({name: 'MainDashboard'});
                    Notification.success(response.data.message);
                })
                .catch(error => {
                  if (!error.response.data.errors) {
                    this.formData.passwordError = error.response.data.message;
                  }
                  this.formData.passwordError = error.response.data.errors.password.toString();
                })
                .finally(() => {
                    this.loading = false;
                })
        },

    }
}
</script>

<style scoped>

</style>