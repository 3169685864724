<template>
  <el-dialog
      :visible.sync="show"
      width="320px"
      class="project-modal"
  >
      <div>
          <div class="text-center mb-3 me-2">{{ text }}</div>

          <div class="d-flex justify-content-center">
              <el-button :loading="loading" @click="success" type="success">OK</el-button>
              <el-button :loading="loading" @click="close">Cancel</el-button>
          </div>
      </div>
  </el-dialog>
</template>

<script>
export default {
  name: "RemoveProfileModal",
  props: {
      show: {
          type: Boolean,
          default: false
      },
      loading: {
          type: Boolean,
          default: false
      },
      text: {
          type: String,
          default: "Do you want to remove your profile picture?"
      }
  },
  methods: {
      close() {
          this.$emit("close");
      },
      success() {
        this.$emit("success");
      }
  }
}
</script>

<style scoped>

</style>